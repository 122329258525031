<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { FormElement, useFormElement } from '../';
import { useCropStore } from '@common/lib/modules/axial';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const { labelComponent, label, hint, attributes, enabled } = useFormElement(props.element);

const cropStore = useCropStore();

const value = computed(() => {
  return cropStore.crops.find((crop) => crop.code === props.element.value)?.name ?? props.element.value;
});
</script>

<template>
  <div
    class="v-input"
    data-testid="crop-name-container">
    <label
      class="v-display-label"
      data-testid="crop-name-label">
      <template v-if="labelComponent">
        <component
          :is="labelComponent"
          :label="label"
          :props="{}"
          :element="element" />
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <div
      class="v-display-value"
      :class="{ 'text-disabled': !enabled }"
      data-testid="crop-name-value">
      {{ value }}
    </div>
    <div
      v-if="!attributes.hideDetails"
      class="v-message">
      {{ hint }}
    </div>
  </div>
</template>
