<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { FormElement, useFormElement } from '../';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const { t } = useI18n();

const { labelComponent, label, hint, attributes, enabled } = useFormElement(props.element);

const value = computed(() => {
  const parcelOrderInfo = props.element.value;

  switch (parcelOrderInfo?.status) {
    case 'ordered':
      return t('productGroup.parcel.parcelOrderInfo.ordered', { orderDate: parcelOrderInfo?.orderDate });
    case 'missingCrop':
      return t('productGroup.parcel.parcelOrderInfo.missingCrop');
    case 'noZoningOrdered':
      return t('productGroup.parcel.parcelOrderInfo.noZoningOrdered');
    default:
      return '-';
  }
});
</script>

<template>
  <div
    class="v-input"
    data-testid="zone-strength-parcel-order-status-container">
    <label
      class="v-display-label"
      data-testid="display-label">
      <template v-if="labelComponent">
        <component
          :is="labelComponent"
          :label="label"
          :props="{}"
          :element="element" />
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <div
      class="v-display-value"
      :class="{ 'text-disabled': !enabled }"
      data-testid="zone-strength-parcel-order-status-value">
      {{ value }}
    </div>
    <div
      v-if="!attributes.hideDetails"
      class="v-message">
      {{ hint }}
    </div>
  </div>
</template>
